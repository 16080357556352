import cx from 'classnames';

import { OpacityScaleType, GradientDirectionType } from './GradientOptions';

export type GradientProps = {
  className?: string;
  from?: OpacityScaleType;
  to?: OpacityScaleType;
  direction?: GradientDirectionType;
};

export const Gradient = ({
  className,
  from = 0.6,
  to = 0.2,
  direction = 'b',
}: GradientProps) => (
  <div
    className={cx(
      'absolute inset-0 z-1',
      'mix-blend-multiply',
      {
        ['bg-gradient-to-t']: direction === 't',
        ['bg-gradient-to-tr']: direction === 'tr',
        ['bg-gradient-to-r']: direction === 'r',
        ['bg-gradient-to-br']: direction === 'br',
        ['bg-gradient-to-b']: direction === 'b',
        ['bg-gradient-to-bl']: direction === 'bl',
        ['bg-gradient-to-l']: direction === 'l',
        ['bg-gradient-to-tl']: direction === 'tl',
      },
      { ['from-[rgba(33,40,59,0)]']: from === 0.0 },
      { ['from-[rgba(33,40,59,0.2)]']: from === 0.2 },
      { ['from-[rgba(33,40,59,0.4)]']: from === 0.4 },
      { ['from-[rgba(33,40,59,0.6)]']: from === 0.6 },
      { ['from-[rgba(33,40,59,0.8)]']: from === 0.8 },
      { ['from-[rgba(33,40,59,1)]']: from === 1 },
      { ['to-[rgba(33,40,59,0)]']: to === 0.0 },
      { ['to-[rgba(33,40,59,0.2)]']: to === 0.2 },
      { ['to-[rgba(33,40,59,0.4)]']: to === 0.4 },
      { ['to-[rgba(33,40,59,0.6)]']: to === 0.6 },
      { ['to-[rgba(33,40,59,0.8)]']: to === 0.8 },
      { ['to-[rgba(33,40,59,1)]']: to === 1 },
      className,
    )}
  />
);
